import React from "react"
import { Helmet } from "react-helmet-async"
import Layout from "./layout"
import EmailSignUp from "./EmailSignUp"
import HymnListItem from "./HymnListItem"
import slugUtil from "../../slug-util"

export default (props) => {
  const meters = props.pageContext.meters;

  function getItemModel(meter) {
    return { href: `/meters/${slugUtil.createSlugForMeter(meter)}`, title: meter  }
  }

  const listItems = meters.map(meter => {
      const model = getItemModel(meter);
      return <HymnListItem key={model.href} model={model} />
    });

  return (
    <Layout>
      <Helmet>
        <title>Meters</title>
      </Helmet>
      <div id="home-page-hymn-lists" className="container">
        <div className="item">
          <h1>Meters</h1>
          <ul>
            {listItems}
          </ul>
        </div>
      </div>

      <div style={{marginTop: `3em`}}>
        <EmailSignUp />
      </div>
    </Layout>
  )
}
